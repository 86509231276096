import { createIcon } from "@chakra-ui/react";

const LockIcon = createIcon({
  displayName: "LockIcon",
  viewBox: "0 0 16 20",
  path: [
    <path
      key="1"
      d="M8.02512 0.815094C9.36592 0.815094 10.5834 1.36576 11.4652 2.25112C12.3469 3.13648 12.8953 4.35906 12.8953 5.70415V7.00015H11.2048V5.70415C11.2048 4.82763 10.8476 4.02942 10.2715 3.4497C9.6942 2.87125 8.89928 2.5113 8.02638 2.5113C7.15348 2.5113 6.35856 2.86999 5.78124 3.4497C5.20518 4.02815 4.84797 4.82637 4.84797 5.70415V7.65042H3.15625V5.70415C3.15625 4.35906 3.70338 3.13648 4.58509 2.25112C5.46679 1.36576 6.68558 0.815094 8.02512 0.815094Z"
      fill="currentColor"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.08388 8.19989H14.917C15.366 8.19989 15.7345 8.56869 15.7345 9.01958V18.5552C15.7345 19.0074 15.3673 19.3749 14.917 19.3749H1.08388C0.63485 19.3749 0.267578 19.0061 0.267578 18.5552V9.01958C0.267578 8.56869 0.63485 8.19989 1.08388 8.19989Z"
      fill="currentColor"
    />,
    <path
      key="3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99767 12C8.72467 12 9.31331 12.5911 9.31331 13.3198C9.31331 13.8516 8.99886 14.3088 8.54984 14.5184L9.37746 18.3958H6.61914L7.43167 14.5108C6.99144 14.2974 6.68454 13.8452 6.68454 13.3198C6.68454 12.5911 7.27319 12 7.99767 12Z"
      fill="white"
    />,
  ],
});

export default LockIcon;
