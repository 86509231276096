import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import CompanyHelpdeskData from "./CompanyHelpdeskData";
import DefaultHelpdeskData from "./DefaultHelpdeskData";

const FI_URL =
  "/files/description-of-the-file/EAI_Incentive_DescriptionOfTheFile_FI.pdf";
const SV_URL =
  "/files/description-of-the-file/EAI_Incentive_DescriptionOfTheFile_SV.pdf";
const EN_URL =
  "/files/description-of-the-file/EAI_Incentive_DescriptionOfTheFile_EN.pdf";

const Helpdesk = ({
  companyId,
  apolloClient,
  locale,
}: {
  companyId?: number | null;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  locale: string;
}) => {
  const descriptionOfTheFileURL =
    locale === "fi" ? FI_URL : locale === "sv" ? SV_URL : EN_URL;

  return (
    <Flex
      flexDirection="column"
      flex={1}
      background="login.helpdeskBackground"
      color="login.helpdeskColor"
    >
      <Flex flexDirection="column" p="8" flex={1}>
        <Text fontWeight="bold" textTransform="uppercase" fontSize="small">
          <FormattedMessage
            id="helpdesk.evliBankEAIHelpdesk"
            defaultMessage="Evli | Allshares help desk"
          />
        </Text>

        {companyId && apolloClient ? (
          <CompanyHelpdeskData
            companyId={companyId}
            apolloClient={apolloClient}
          />
        ) : (
          <DefaultHelpdeskData />
        )}

        <Box mt="7">
          <Text lineHeight="1" fontSize="smaller">
            <FormattedMessage
              id="helpdesk.descriptionText"
              defaultMessage="The Incentive portal is a customized service platform for the
        share plan participants’ needs – follow your ownership, read
        materials, make transactions and much more."
            />
          </Text>
        </Box>

        <Box mt="6" fontSize="sm" textDecoration="underline">
          <Link href={descriptionOfTheFileURL} isExternal>
            <FormattedMessage
              id="helpdesk.registryDescriptionLink"
              defaultMessage="See registry description"
            />
          </Link>
        </Box>
      </Flex>

      <Flex
        background="login.helpdeskDisclaimerBackground"
        height="140px"
        p="8"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Divider borderColor="gray.400" />
        <FormattedMessage
          id="helpdesk.disclaimer"
          defaultMessage="<note>Please note!</note> <content> All acts of misuse and abuse, or any attemps of such, will be automatically reported to the relevant authorities for investigation and required action.</content> "
          values={{
            note: (chunks) => (
              <Text
                fontSize="small"
                fontWeight="bold"
                textTransform="uppercase"
                color="color"
              >
                {chunks}
              </Text>
            ),
            content: (chunks) => (
              <Text color="color" lineHeight="1" fontSize="12px">
                {chunks}
              </Text>
            ),
          }}
        />
      </Flex>
    </Flex>
  );
};

export default Helpdesk;
